import { TextField } from '@mui/material';
import valid from 'card-validator';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';

import { AppInput } from 'src/components/app-input';
import { AppButton } from 'src/components/button';
import { Copyright } from 'src/components/copyright';
import { InfoActionLayout } from 'src/components/info-action-layout';
import { Loader } from 'src/components/loader';

import { getApplicationConfig } from 'src/configuration/config.ts';
import { EMAIL_VALIDATOR_REGEX, NAME_VALIDATOR_REGEX, US_PHONE_REGEX } from 'src/configuration/validators';

import { useAppRoute } from 'src/hooks/useAppRoute';
import { useThunkAction } from 'src/hooks/useThunkAction';

import { TEXT_VARS } from 'src/i18n/en';

import { AppRoutes } from 'src/router/config';

import {
    StyledBody,
    StyledCancelButton,
    StyledCardInfo,
    StyledCardRowText,
    StyledConfirmText,
    StyledContinueButton,
    StyledInputs,
    StyledTitle,
} from 'src/screens/enter-basic-info/enter-basic-info.styled';

import {
    selectChangeFlow,
    selectFirstRenderBankAcc,
    selectLinkFlow,
    selectVirtualCustomerId,
} from 'src/store/app/selectors';
import { mergeCustomer } from 'src/store/customer/actions.thunk.ts';
import { cancelPayment } from 'src/store/payment/actions.thunks';
import {
    createOrUpdateCustomer,
    generateJWTToken,
    getCurrentCustomer,
    getPubKey,
    signOut,
} from 'src/store/user/actions.thunks';
import { saveResponseError } from 'src/store/user/reducer.ts';
import {
    selectCustomer,
    selectIsCustomerNew,
    selectPrepayment,
    selectQueryParams,
    selectUserEmail,
    selectUserName,
    selectUserPhone,
} from 'src/store/user/selectors';

import { StyledPageWithPaddingContainer } from 'src/theme/shared-styled-components';

import { ApplicationConfig } from 'src/types/applicationConfig.type.ts';

import { getEncryptedData } from 'src/utils/encryptCard.ts';

const ENTER_BASIC_INFO_CANCEL_CALLBACK = 'cancelCallback';
const CANCEL_PAYMENT = 'cancelPaymentAction';
const CANCEL_PAYMENT_COMPLETED = 'cancelPaymentActionCompleted';

export const EnterBasicInfo = () => {
    const route = useAppRoute();
    const dispatch = useDispatch();

    const [
        createOrUpdateCustomerAction,
        [isCreateOrUpdateCustomerLoading],
        [updatingError, setUpdatingError],
    ] = useThunkAction(createOrUpdateCustomer);

    const [cancelPaymentAction, [isCancelPaymentLoading], [cancelPaymentError, setCancelPaymentError]] =
        useThunkAction(cancelPayment);

    const [
        generateJWTTokenAction,
        [isGenerateJWTTokenLoading],
        [generateJWTTokenError, setGenerateJWTTokenError],
    ] = useThunkAction(generateJWTToken);

    const [
        getCurrentCustomerAction,
        [isLoadingGetCurrentCustomer],
        // [getCurrentCustomerError, setGetCurrentCustomerError],
    ] = useThunkAction(getCurrentCustomer);

    const [getPubKeyAction] = useThunkAction(getPubKey);
    const [mergeCustomerAction, [isMergeCustomerLoading], [mergeCustomerError, setMergeCustomerError]] =
        useThunkAction(mergeCustomer);

    const queryParams = useSelector(selectQueryParams);
    const linkFlow = useSelector(selectLinkFlow);

    const currentCustomer = useSelector(selectCustomer);

    const currentUserName = useSelector(selectUserName);
    const currentUserEmail = useSelector(selectUserEmail);
    const currentUserPhone = useSelector(selectUserPhone);
    const isCustomerNew = useSelector(selectIsCustomerNew);
    const prepayment = useSelector(selectPrepayment);
    const isFirstRenderBankAcc = useSelector(selectFirstRenderBankAcc);
    const isChangeFlow = useSelector(selectChangeFlow);
    const virtualCustomerId = useSelector(selectVirtualCustomerId);
    const [receivedCardNumber, setReceivedCardNumber] = useState('');

    const authData = useMemo(() => {
        return prepayment
            ? { paymentLinkToken: prepayment.shortCode, merchantId: prepayment.merchantId }
            : { apiToken: queryParams?.apiToken };
    }, [prepayment, queryParams?.apiToken]);

    useEffect(() => {
        if (isChangeFlow) {
            getCurrentCustomerAction().then(r => {
                if (!r.success) {
                    return;
                }
                dispatch(saveResponseError(undefined));
                generateJWTTokenAction(authData);
            });
        }
    }, [authData, dispatch, generateJWTTokenAction, getCurrentCustomerAction, isChangeFlow]);

    const { env }: ApplicationConfig = getApplicationConfig();

    const userNameFromMerchantStore = useMemo(() => {
        const isName = queryParams?.firstName || queryParams?.lastName;
        return isName ? `${queryParams?.firstName} ${queryParams?.lastName}`.trim() : null;
    }, [queryParams]);

    const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
    const [name, setName] = useState(
        userNameFromMerchantStore || currentCustomer?.name || currentUserName || ''
    );
    const [email, setEmail] = useState(
        queryParams?.email || currentCustomer?.email || currentUserEmail || ''
    );
    const [phoneNumber, setPhoneNumber] = useState(
        queryParams?.phone || currentCustomer?.phoneNumber || currentUserPhone || ''
    );
    const [isInputError, setIsInputError] = useState<boolean>(false);
    const [isCancelPopupDisplayed, setIsCancelPopupDisplayed] = useState<boolean>(false);
    const [isCancelPaymentSuccess, setIsCancelPaymentSuccess] = useState<boolean>(false);
    const [cardNumber, setCardNumber] = useState('');

    const [expDate, setExpDate] = useState<string>('');
    const expMonth = parseInt(expDate.slice(0, 2));
    const expYear = parseInt(expDate.slice(5, 7));

    const [pubKey, setPubKey] = useState<string>('');

    const handleInputCardNumberChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setCardNumber(e.target.value);
    }, []);

    const isCardNumberValid = useMemo(() => {
        const _isNumberValid = valid.number(cardNumber, {
            skipLuhnValidation: env == 'DEV' || env == 'STAGE',
        }).isValid;
        if (isFirstRender) {
            return true;
        }
        return receivedCardNumber ? _isNumberValid : true;
    }, [cardNumber, isFirstRender, env, receivedCardNumber]);

    const cardNumberHelperText = useMemo(() => {
        return isCardNumberValid ? undefined : TEXT_VARS.ERRORS.YOUR_CARD_IS_INVALID;
    }, [isCardNumberValid]);

    const handleInputExpDateChange = useCallback((e: ChangeEvent<{ value: unknown }>) => {
        setExpDate(e.target.value as string);
    }, []);

    const isCardExpDateValid = useMemo(() => {
        const _isExpDateValid = (() => {
            if (expMonth > 12) {
                return false;
            }
            const currentDate = new Date();

            // expire year is in format: @number XX
            // expire month is in format: @number XX (from 1 to 12)
            const expireCardDate = new Date(expYear + 2000, expMonth - 1);

            return expireCardDate > currentDate;
        })();

        if (isFirstRender) {
            return true;
        }
        return receivedCardNumber ? _isExpDateValid : true;
    }, [expMonth, expYear, isFirstRender, receivedCardNumber]);

    const cardExpDateHelperText = isCardExpDateValid ? undefined : TEXT_VARS.ERRORS.DATE_IS_INVALID;

    const isConsentFlow = linkFlow === 'CONSENT_LINK';

    useEffect(() => {
        if (!currentCustomer) {
            return;
        }

        if (currentCustomer.name && currentCustomer.email && currentCustomer.phoneNumber) {
            setName(currentCustomer.name);
            setEmail(currentCustomer.email);
            setPhoneNumber(currentCustomer.phoneNumber);
        }
    }, [currentCustomer]);

    useEffect(() => {
        setUpdatingError(undefined);
        setCancelPaymentError(undefined);
    }, [setUpdatingError, setCancelPaymentError]);

    const createOrUpdateCustomerFunc = useCallback(async () => {
        if (isConsentFlow) {
            route(AppRoutes.customerSelectYourBankAccount);
            return;
        }

        await createOrUpdateCustomerAction({ name, email, phoneNumber }).then(r => {
            if (!r.success) {
                return;
            }
            generateJWTTokenAction(authData).then(async () => {
                if (!r.success) {
                    return;
                }

                let isMergeError = false;
                if (isChangeFlow && virtualCustomerId) {
                    const encryptedData =
                        receivedCardNumber &&
                        (await getEncryptedData({ publicKey: pubKey, cardNumber, expMonth, expYear }));

                    await mergeCustomerAction({
                        virtualCustomerId,
                        data: { encryptedData: receivedCardNumber ? encryptedData : '' },
                    }).then(r => {
                        if (!r.success && r.error.message.status == '412') {
                            isMergeError = true;
                            setReceivedCardNumber(r.error.message.response.data.reducedCardNumber);
                            setMergeCustomerError(undefined);
                            return;
                        }

                        if (!r.success) {
                            isMergeError = true;
                            return;
                        }
                    });
                }

                !isMergeError &&
                    setTimeout(() => {
                        route(AppRoutes.customerSelectYourBankAccount);
                    }, 1000);
            });
        });
    }, [
        isConsentFlow,
        createOrUpdateCustomerAction,
        name,
        email,
        phoneNumber,
        route,
        generateJWTTokenAction,
        authData,
        isChangeFlow,
        virtualCustomerId,
        receivedCardNumber,
        pubKey,
        cardNumber,
        expMonth,
        expYear,
        mergeCustomerAction,
        setMergeCustomerError,
    ]);

    const handleContinueClick = useCallback(() => {
        setIsFirstRender(false);

        if (isInputError) {
            return;
        }

        if (!name || !email || !phoneNumber) {
            return;
        }
        createOrUpdateCustomerFunc();
    }, [isInputError, name, email, phoneNumber, createOrUpdateCustomerFunc]);

    const handleOpenCancelPaymentPopup = useCallback(() => {
        setIsCancelPopupDisplayed(true);
    }, []);

    const handleCancelClick = useCallback(() => {
        if (!queryParams) {
            setCancelPaymentError('QueryParams unavailable, but expected');
            return;
        }

        return cancelPaymentAction({
            payment: {
                amount: queryParams.amount,
                currency: queryParams.currency,
                description: queryParams.description,
                merchantOrderId: queryParams.orderId,
            },
        })
            .then(r => {
                if (!r.success) {
                    return;
                }
                setIsCancelPaymentSuccess(true);
            })
            .finally(() => {
                window.top?.postMessage({ cancelAction: ENTER_BASIC_INFO_CANCEL_CALLBACK }, '*');
            });
    }, [cancelPaymentAction, queryParams, setCancelPaymentError]);

    const isButtonContinueDisabled = useMemo(() => {
        return !(name && email);
    }, [email, name]);

    const handleKeypress = useCallback(
        async (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                await handleContinueClick();
            }
        },
        [handleContinueClick]
    );

    const handleInfoLayoutActionClick = useCallback(() => {
        setUpdatingError(undefined);
        setCancelPaymentError(undefined);
        setMergeCustomerError(undefined);
        setGenerateJWTTokenError(undefined);
    }, [setUpdatingError, setCancelPaymentError, setMergeCustomerError, setGenerateJWTTokenError]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeypress);
        return () => document.removeEventListener('keydown', handleKeypress);
    }, [handleKeypress]);

    const isErrorDisplayed: boolean = useMemo(() => {
        return !!(updatingError || cancelPaymentError || generateJWTTokenError || mergeCustomerError);
    }, [updatingError, cancelPaymentError, generateJWTTokenError, mergeCustomerError]);

    const cancelPaymentPopupTitle = useMemo(() => {
        return isCancelPaymentSuccess
            ? TEXT_VARS.COMMON_TEXT.YOUR_ORDER_CANCELED
            : TEXT_VARS.COMMON_TEXT.CANCEL_PURCHASE;
    }, [isCancelPaymentSuccess]);

    const handler = useCallback(
        async (event: MessageEvent) => {
            {
                if (event.data.cancelPayment === CANCEL_PAYMENT) {
                    await handleCancelClick();
                    await signOut();
                    window.top?.postMessage({ cancelPaymentActionCompleted: CANCEL_PAYMENT_COMPLETED }, '*');
                }
            }
        },
        [handleCancelClick]
    );
    useEffect(() => {
        window.addEventListener('message', handler);
        return () => {
            window.removeEventListener('message', handler);
        };
    }, [handler]);

    useEffect(() => {
        if (!isCustomerNew && name && email && phoneNumber && isFirstRenderBankAcc && !isChangeFlow) {
            createOrUpdateCustomerFunc();
        }
    }, [
        createOrUpdateCustomerFunc,
        email,
        isCustomerNew,
        name,
        phoneNumber,
        isFirstRenderBankAcc,
        isChangeFlow,
    ]);

    useEffect(() => {
        if (isChangeFlow) {
            getPubKeyAction().then(async r => {
                if (!r.success) return;
                const payload = r.payload as { publicKey: string };
                setPubKey(payload.publicKey);
            });
        }
    }, [getPubKeyAction, isChangeFlow]);

    return (
        <>
            <StyledPageWithPaddingContainer $isPointerEventsDisabled={!!updatingError}>
                <Loader
                    isShowing={
                        isCreateOrUpdateCustomerLoading ||
                        isCancelPaymentLoading ||
                        isGenerateJWTTokenLoading ||
                        isLoadingGetCurrentCustomer ||
                        isMergeCustomerLoading
                    }
                />
                <StyledTitle>
                    <span>{TEXT_VARS.TITLE.COMPLETE_YOUR_PROFILE}</span>
                </StyledTitle>
                <StyledInputs>
                    <AppInput
                        className="input"
                        handleInputChange={setName}
                        inputErrorText={TEXT_VARS.ERRORS.NAME_IS_INVALID}
                        inputValidationRegexp={NAME_VALIDATOR_REGEX}
                        inputValue={name}
                        label="Name"
                        name="first-name-input"
                        placeholder="Name ..."
                        setIsValidationError={setIsInputError}
                    />
                    <AppInput
                        className="input"
                        handleInputChange={setEmail}
                        inputErrorText={TEXT_VARS.ERRORS.EMAIL_IS_INVALID}
                        inputValidationRegexp={EMAIL_VALIDATOR_REGEX}
                        inputValue={email}
                        label="Email"
                        name="email-input"
                        placeholder="email"
                        setIsValidationError={setIsInputError}
                    />
                    <AppInput
                        className="input"
                        firstRender={isFirstRender}
                        handleInputChange={setPhoneNumber}
                        inputErrorText={TEXT_VARS.ERRORS.PHONE_NUMBER_IS_INVALID}
                        inputValidationRegexp={US_PHONE_REGEX}
                        inputValue={phoneNumber}
                        label="Phone Number"
                        name="phone-input"
                        placeholder="Phone number ..."
                        setIsValidationError={setIsInputError}
                    />
                </StyledInputs>
                {isChangeFlow && receivedCardNumber && (
                    <>
                        <StyledConfirmText>
                            Confirm your card ending in <span>{receivedCardNumber}</span>
                        </StyledConfirmText>
                        <StyledBody>
                            <StyledCardInfo>
                                <StyledCardRowText>
                                    <span>{TEXT_VARS.COMMON_TEXT.CARD_NUMBER}</span>

                                    <ReactInputMask
                                        mask="9999999999999999999"
                                        // max card length - 19 digits
                                        maskChar={null}
                                        onChange={handleInputCardNumberChange}
                                        value={cardNumber}
                                    >
                                        {() => (
                                            <TextField
                                                className="card-number"
                                                error={!isCardNumberValid}
                                                helperText={cardNumberHelperText}
                                                placeholder="************ 1234"
                                            />
                                        )}
                                    </ReactInputMask>
                                </StyledCardRowText>
                                <StyledCardRowText>
                                    <span>{TEXT_VARS.COMMON_TEXT.EXPIRY_DATE}</span>
                                    <ReactInputMask
                                        mask="99 / 99"
                                        maskChar={null}
                                        onChange={handleInputExpDateChange}
                                        value={expDate}
                                    >
                                        {() => (
                                            <TextField
                                                className="exp-cvv-text"
                                                error={!isCardExpDateValid}
                                                helperText={cardExpDateHelperText}
                                                placeholder="MM / YY"
                                                type="text"
                                            />
                                        )}
                                    </ReactInputMask>
                                </StyledCardRowText>
                            </StyledCardInfo>
                        </StyledBody>
                    </>
                )}
                <StyledContinueButton>
                    <AppButton
                        appButtonType="Continue"
                        disabled={isButtonContinueDisabled || !isCardNumberValid || !isCardExpDateValid}
                        onClick={handleContinueClick}
                    >
                        {TEXT_VARS.BUTTON.CONTINUE}
                    </AppButton>
                </StyledContinueButton>
                {!isChangeFlow && (
                    <StyledCancelButton>
                        <AppButton appButtonType="Cancel" onClick={handleOpenCancelPaymentPopup}>
                            {TEXT_VARS.BUTTON.CANCEL}
                        </AppButton>
                    </StyledCancelButton>
                )}
                <Copyright />
            </StyledPageWithPaddingContainer>
            {isCancelPopupDisplayed && !cancelPaymentError && (
                <InfoActionLayout
                    buttonTitle={TEXT_VARS.BUTTON.YES}
                    isActionComplete={isCancelPaymentSuccess}
                    linkButtonTitle={TEXT_VARS.BUTTON.NEVERMIND}
                    onClick={handleCancelClick}
                    setIsCancelPopupDisplayed={setIsCancelPopupDisplayed}
                    titleText={cancelPaymentPopupTitle}
                    type="error"
                />
            )}

            {isErrorDisplayed && (
                <InfoActionLayout
                    buttonTitle={TEXT_VARS.COMMON_TEXT.TRY_AGAIN}
                    onClick={handleInfoLayoutActionClick}
                    titleText={TEXT_VARS.COMMON_TEXT.OOPS}
                    type="error"
                />
            )}
        </>
    );
};
